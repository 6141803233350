import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.1,
});

datadogRum.init({
  applicationId: process.env.REACT_APP_DD_RUM_APP_ID,
  clientToken: process.env.REACT_APP_DD_RUM_CLIENT_TOKEN,
  env: process.env.REACT_APP_ENV,
  service: 'checkr-wallet-standalone',
  site: 'datadoghq.com',
});
