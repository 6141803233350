import { SessionContext } from '../sessionContext';
import axios from 'axios';
import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';

const deleteSession = (session) => {
  axios.delete(`${process.env.REACT_APP_PAY_SERVER_URL}/worker/v0/session`, {
    headers: {
      'Content-Type': 'application/json',
      'x-checkr-temporary-auth-token': session.tat.data.temporaryAuthenticationToken,
    },
  });
};

export const useSessionLogout = (options) => {
  const queryClient = useQueryClient();
  const { session } = useContext(SessionContext);

  return useMutation({
    mutationFn: () => deleteSession(session),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['createSession'] });
      options?.onSuccess();
    },
  });
};
