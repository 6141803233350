import { useContext, createContext } from 'react';
import { Navigate } from 'react-router-dom';

const SessionContext = createContext();

function RequireAuth({ children }) {
  const { session } = useContext(SessionContext);

  return session.loggedIn ? children : <Navigate to="/login" replace />;
}

export { SessionContext, RequireAuth };
