import { useSessionLogout } from '../queries/useSession';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Logout() {
  const navigateTo = useNavigate();

  const { mutate: logout, isLoading } = useSessionLogout({
    onSuccess: () => {
      window.localStorage.clear();
      navigateTo('/login');
    },
  });

  useEffect(() => {
    logout();
  }, []);

  if (isLoading) {
    return <div>Logging out...</div>;
  }
}

export default Logout;
