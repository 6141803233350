import { useContext, useEffect, useState } from 'react';
import { SessionContext } from '../sessionContext';
import { Navigate, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';

const HorizontalFlex = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #f0f0f4;
`;

const VerticalFlex = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CheckrPay = styled.div`
  display: flex;
  flex-direction: column;
  background: #f6f6f7;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
`;

const StyledTitleSmall = styled.div`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  line-height: 38px;

  color: #003975;

  font-size: 20px;
  //   padding-top: 50px;
  height: 75px;
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
  box-shadow: 1px 4px 6px #ddd;
`;

const CheckrIframe = styled.iframe`
  width: 380px;
  //   height: 700px;
  height: calc(100vh - 75px);
  border: none;
  padding-left: 5px;
  padding-right: 5px;
`;

const StyledLogoutButton = styled.button`
  background: transparent;
  border: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  svg {
    width: 22px;
    height: 22px;
    fill: #c03c3c;
  }
  span {
    text-transform: capitalize;
    font-size: 10px;
    color: #c03c3c;
  }
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
`;

const StyledConfirmationmodal = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    height: 100%;
    z-index: 99;
    

    .backdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba(0,0,0,0.3);
    }
    .inner-container {
        padding: 50px;
        background-color: #fff;
        position: relative;
        z-index: 2;

        h3 {
            line-height: 1;
        }
        p {
            font-size: 16px;
        }
    }
    .buttons-container {
        display: flex;
        gap: 30px;
        justify-content: center;
        margin-top  15px;
    }
    button {
        padding: 10px 20px;
        border-radius: 10px;
        border: none;
        background: rgb(0, 57, 117);
        color: rgb(255, 255, 255);
        font-family: Outfit;
        font-size: 0.875rem;
        font-weight: 600;
        cursor: pointer;
        &.logout {
            background-color: #c03c3c;
        }
    }
`;

function Dashboard() {
  const { session } = useContext(SessionContext);
  const [error, setError] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const navigateTo = useNavigate();

  useEffect(() => {
    // On page load, verify TAT is still valid.
    axios
      .get(`${process.env.REACT_APP_PAY_SERVER_URL}/worker/v0/profile`, {
        headers: {
          'Content-Type': 'application/json',
          'x-checkr-temporary-auth-token': session.tat.data.temporaryAuthenticationToken,
        },
      })
      .catch((e) => {
        setError(true);
        console.log(e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return <Navigate to="/logout" replace />;
  }

  const logoutModalHandler = () => {
    // navigateTo('/logout')
    setOpenModal((prevState) => (prevState = !prevState));
  };

  const logoutHandler = () => {
    navigateTo('/logout');
  };

  return (
    <HorizontalFlex>
      <VerticalFlex>
        <CheckrPay>
          <StyledTitleSmall>
            Checkr Pay Dashboard
            <StyledLogoutButton onClick={logoutModalHandler}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                {/* eslint-disable-next-line max-len */}
                <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z" />
              </svg>
              <span>logout</span>
            </StyledLogoutButton>
            {openModal && (
              <StyledConfirmationmodal>
                <div className="backdrop" onClick={logoutModalHandler}></div>
                <div className="inner-container">
                  <h3>Logout?</h3>
                  <p>Are you sure you want to logout?</p>
                  <div className="buttons-container">
                    <button className="logout" onClick={logoutHandler}>
                      Logout
                    </button>
                    <button onClick={logoutModalHandler}>Cancel</button>
                  </div>
                </div>
              </StyledConfirmationmodal>
            )}
          </StyledTitleSmall>
          <CheckrIframe
            title="Embeddable"
            src={
              process.env.REACT_APP_CDN_URI +
              `?tempAuthToken=${session.tat.data.temporaryAuthenticationToken}&referer=web-wallet`
            }
          />
        </CheckrPay>
      </VerticalFlex>
    </HorizontalFlex>
  );
}

export default Dashboard;
