import './App.css';

import { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './features/login';
import Logout from './features/logout';
import Authenticate from './features/authenticate';
import Dashboard from './features/dashboard';
import { SessionContext, RequireAuth } from './sessionContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import useStickyState from './useStickyState';

const queryClient = new QueryClient();

function App() {
  const [session, setSession] = useStickyState({ loggedIn: false }, 'session');

  useEffect(() => {
    const appHostWithProtocol = new URL(process.env.REACT_APP_HOST).host;

    if (window.location.host !== appHostWithProtocol) {
      window.location.replace(process.env.REACT_APP_HOST);
    }
  }, []);

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <SessionContext.Provider value={{ session, setSession }}>
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <Dashboard />
                  </RequireAuth>
                }
              />
              <Route path="/login" element={<Login />} />
              <Route
                path="/logout"
                element={
                  <RequireAuth>
                    <Logout />
                  </RequireAuth>
                }
              />
              <Route path="/authenticate" element={<Authenticate />} />
            </Routes>
          </BrowserRouter>
        </SessionContext.Provider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
