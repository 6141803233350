import { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from 'axios';
import { ReactComponent as Logo } from '../assets/icons/checkr-pay-logo.svg';

const StyledLoginContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (max-width: 900px) {
    display: block;
  }
`;

const StyledLoginHelp = styled.div`
  font-size: 0.9rem;
`;

const StyledFormError = styled.div`
  border: 1px solid #dc3545;
  border-radius: 5px;
  color: #dc3545;
  text-align: left;
  padding: 10px 15px;
`;

const StyledSupportHelp = styled.div`
  margin-top: 30px;
  font-size: 0.9rem;
`;

const StyledSideBar = styled.div`
  flex: 1;
  flex-direction: column;
  height: 100%;
  padding: 70px 50px;
  background: linear-gradient(12.83deg, #009bb5 -13.95%, #3fb79c 116.74%), #f6f6f7;
  background-image: url('/bg-side.svg');
  background-size: cover;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    max-width: 100%;
  }
  @media (max-width: 900px) {
    border-top-right-radius: 0;
    border-bottom-left-radius: 10px;
    padding: 20px;
    height: auto;
  }
`;

const StyledSideBarText = styled.div`
color: #FFF;
line-height: 1.5;
margin-top 30px;
a {
  color: #FFF;
}

@media (max-width: 900px) {
  display: none;
}
`;

const StyledMain = styled.div`
  flex: 3;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  @media (max-width: 900px) {
    height: auto;
    padding-top: 20%;
  }
`;

const LoginBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 500px;
  background: #f6f6f7;
  border-radius: 5px;
  gap: 10px;
  padding: 30px;
  background: #f6f6f7;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
`;

const StyledTitleSmall = styled.div`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
`;

const StyledLogo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 20px;

  width: 180px;
  height: 33px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 80%;
  margin-top: 10px;
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const gradient = keyframes`
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
`;
const StyledButton = styled.button`
  width: 100%;
  background: linear-gradient(90deg, #009bb5 0%, #04c1e0 0%, #3fb79c 100%);
  background-size: 200% 200%;
  color: #fff;
  padding: 22px 35px;
  font-size: 14px;
  font-weight: 600;
  border: 0;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  > svg {
    margin-left: 7px;
    transition: all 0.3s;
  }
  &:hover {
    animation: ${gradient} 5s ease infinite;
    > svg {
      margin-left: 10px;
    }
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 3px;
  padding: 0 10px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 600;
  color: #003975;
  border: 0;
  background-color: #e4e7ed;
  border-radius: 10px;
  padding: 22px 20px;
  &::placeholder {
    color: #003975;
  }
  &:focus {
    outline: 2px solid #003975;
  }
`;

const StyledB = styled.b`
  color: #576c82;
`;

function Login() {
  const [emailAddress, setEmailAddress] = useState('');
  const [pageState, setPageState] = useState('login');
  const [didAuthenticatedFail, setDidAuthenticatedFail] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);

  const handleChange = (e) => {
    setEmailAddress(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsFormLoading(true);
    setDidAuthenticatedFail(false);

    axios
      .get(
        `${
          process.env.REACT_APP_PAY_SERVER_URL
        }/worker/v0/get_magic_link?email=${encodeURIComponent(emailAddress)}`
      )
      .then(() => {
        setPageState('linkRequested');
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setDidAuthenticatedFail(true);
        } else {
          setPageState('error');
        }
      })
      .finally(() => {
        setIsFormLoading(false);
      });
  };

  let content;
  switch (pageState) {
    case 'login':
      content = (
        <StyledForm onSubmit={handleSubmit}>
          {didAuthenticatedFail && (
            <StyledFormError>
              Incorrect email. Please check the email and try again.
            </StyledFormError>
          )}
          <StyledInput
            name="emailAddress"
            type="email"
            required={true}
            placeholder="Email Address"
            value={emailAddress}
            onChange={handleChange}
          />
          <StyledLoginHelp>We will send a link to log in to this email</StyledLoginHelp>
          <StyledButton type="submit" disabled={isFormLoading}>
            Send Login Link
            <svg
              width="18"
              height="16"
              viewBox="0 0 18 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 8L17 8M10 1L17 8L10 15"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </StyledButton>
        </StyledForm>
      );
      break;
    case 'linkRequested':
      content = <StyledB>Check your email for a login link, and click on it to log in.</StyledB>;
      break;
    case 'error':
    default:
      content = <StyledB>Error! Please contact Checkr Pay support for assistance.</StyledB>;
      break;
  }

  return (
    <StyledLoginContainer>
      <StyledSideBar>
        <img src={process.env.PUBLIC_URL + '/Checkr-Pay-logo-white.svg'} alt="Checkr logo" />
        <StyledSideBarText>
          <strong>Checkr Pay</strong> is the easiest and fastest way to get paid for your gig work.
          Log in to manage your earnings.
        </StyledSideBarText>
      </StyledSideBar>
      <StyledMain>
        <LoginBox>
          <StyledLogo>
            <Logo />
          </StyledLogo>
          <StyledTitleSmall>Log in to manage your earnings</StyledTitleSmall>
          {content}
          <StyledSupportHelp>
            Having trouble with login? <a href="https://support.checkrpay.com">Contact support</a>{' '}
            for assistance.
          </StyledSupportHelp>
        </LoginBox>
      </StyledMain>
    </StyledLoginContainer>
  );
}

export default Login;
