import { useEffect, useContext } from 'react';
import { useSearchParams, Navigate } from 'react-router-dom';
import { SessionContext } from '../sessionContext';
import { useQuery } from 'react-query';
import axios from 'axios';

function Authenticate() {
  const [searchParams] = useSearchParams();
  const { setSession } = useContext(SessionContext);

  // One-time session fetch
  const { isLoading, isError, isSuccess, data } = useQuery({
    queryKey: 'createSession',
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    queryFn: async () => {
      const token = searchParams.get('token');
      const res = await axios.get(
        `${process.env.REACT_APP_PAY_SERVER_URL}/worker/v0/create_session?stytchToken=${token}`
      );
      return res;
    },
  });

  // Persist once loggeed in
  useEffect(() => {
    if (isSuccess) {
      setSession({
        loggedIn: true,
        tat: data,
      });
    }
  }, [isSuccess, data, setSession]);

  let content;
  if (isLoading) {
    content = <b>Loading...</b>;
  } else if (isError) {
    content = <b>Error! Please contact Checkr Pay Support for assistance.</b>;
  } else if (isSuccess) {
    content = <Navigate to="/" replace />;
  }

  return <div>{content}</div>;
}

export default Authenticate;
